import React, {useRef, useState} from 'react';
import './style.less'
import common from "../../utils/common";

//编辑常用网站
function EditSite(props) {
    const {onOk, webSiteList} = props

    const imgRef = useRef(null)

    const [activeItem, setActiveItem] = useState({})        // 选中的网站
    const [imgUrl, setImgUrl] = useState("")                // 图片url
    const [data, setData] = useState({                      // 表单数据
        'name': '',         // 网站名
        'url': '',          // 网站地址
        'fileKey': '',      // 网站图fileKey
    })
    const [handleType, setHandleType] = useState(null)      // 操作类型 10:新增 20:编辑

    // 保存
    let save = () => {
        if (data.name.length < 1) {
            common.toast('网站名不能为空')
            return
        }

        if (data.url.length < 1) {
            common.toast('网站地址不能为空')
            return
        }

        if (!handleType) {
            common.toast('操作类型异常')
            return
        }

        // 编辑
        if (handleType === 20) {
            common.loadingStart()
            common.axios("post", "/account/default/update-website", {
                'name': data.name,
                'url': data.url,
                'fileKey': data.fileKey,
                'id': activeItem.id,
            }).catch((errorObj) => {
                // 失败
                common.toast(errorObj.error);
            }).then((res) => {
                common.toast(res)
                onOk()
            }).finally(() => common.loadingStop())

            return;
        }

        // 新增
        common.toast('新增')
        common.loadingStart()
        common.axios("post", "/account/default/add-website", {
            'name': data.name,
            'url': data.url,
            'fileKey': data.fileKey,
        }).then((res) => {
            common.toast(res)
            onOk()
        }).catch((errorObj) => {
            // 失败
            common.toast(errorObj.error);
        }).finally(() => common.loadingStop())
    }

    // 删除网站
    let deleteWeb = () => {
        if (activeItem.id === undefined) {
            common.toast('请选需要删除的网站')
            return
        }

        common.confirm("确定删除？", () => {
            common.loadingStart()
            common.axios("post", "/account/default/remove-one-website", {id: activeItem.id}).then((res) => {
                common.toast(res)
                onOk()
            }).catch((errorObj) => {
                // 失败
                common.toast(errorObj.error);
            }).finally(() => common.loadingStop())
        })
    }

    // 上传图片
    let uploadImg = (files) => {
        let form = new FormData();
        form.append('files', files, files.name)

        common.loadingStart()
        common.ajax('post', '/account/default/upload', form, {'contentType': 'multipart/form-data'}).then((res) => {
            let pic = res[0]
            setData({...data, 'fileKey': pic.fileKey})
            setImgUrl(pic.url)
        }).finally(() => {
            common.loadingStop()
        })
    }

    return (
        <div className="EditSite">
            {/*<div className="title-div">*/}
            {/*    <div className="title-font-div">编辑常用网站</div>*/}
            {/*    <img src={require('@/images/close.png')} className="title-close-img" alt=""/>*/}
            {/*</div>*/}

            <div className="content-box-div">
                <div className="menu-div">
                    <div className={'menu-item-content-div'}>
                        {
                            webSiteList.map((item, key) =>
                                <div key={key}
                                     className={activeItem.id && item.id === activeItem.id ? 'menu-item-div menu-active' : 'menu-item-div'}
                                     onClick={() => {
                                         setActiveItem(item)
                                         setData({
                                             'name': item.name,
                                             'url': item.url,
                                             'fileKey': "",
                                         })
                                         setImgUrl(item.cover_url)
                                         setHandleType(20)
                                     }}>
                                    <div className="menu-img-div">

                                        <img src={item.cover_url} className="menu-img" alt=""/>
                                    </div>
                                    <div className="menu-content-div">
                                        <div className="menu-title-div">{item.name}</div>
                                        <div className="menu-url-div">{item.url}</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className={'create-btn-box'}>
                        <div className={'create-btn'} onClick={() => {
                            setActiveItem({})
                            setData({
                                'name': '',
                                'url': '',
                                'fileKey': '',
                            })
                            setImgUrl("")
                            setHandleType(10)
                        }}>新增
                        </div>
                    </div>

                </div>

                {
                    handleType && <div className="right-content-div">
                        <div className="form-content-box-div">

                            <div className="flex-row-div">
                                <div className="left-div">网站名</div>
                                <div className="right-div">
                                    <input className="right-input" type="text" placeholder="请输入网站名" onChange={(e) => {
                                        setData({...data, 'name': e.target.value})
                                    }} value={data.name}/>
                                </div>
                            </div>

                            <div className="flex-row-div">
                                <div className="left-div">网站地址</div>
                                <div className="right-div">
                                    <input className="right-input" type="text" value={data.url} onChange={(e) => {
                                        setData({...data, 'url': e.target.value})
                                    }} placeholder="请输入网站地址"/>
                                </div>
                            </div>

                            <div className="flex-row-div">
                                <div className="left-div">图标</div>
                                <div className="right-div">
                                    <div className="icon-div" onClick={() => imgRef.current.click()}>
                                        {
                                            imgUrl.length > 0 ? <img className={'icon-img'} src={imgUrl}/> :
                                                <>
                                                    <img src={require('@/images/add.png')} className="add-img" alt=""/>
                                                    <div>上传图标</div>
                                                </>
                                        }
                                    </div>

                                    <input ref={imgRef} style={{display: 'none'}} type="file" value={''} onChange={(e) => {
                                        uploadImg(e.target.files[0])
                                    }}/>
                                </div>
                            </div>
                        </div>

                        <div className="bottom-box-div">
                            <div className="btn-div cancel-btn" onClick={deleteWeb}>删除</div>
                            <div className="btn-div submit-btn" onClick={save}>保存</div>
                        </div>
                    </div>
                }


            </div>
        </div>
    );
}

export default EditSite;