import common from "./common";
import biz from "./biz";
import {setRoomList, setEmployeeBookingList} from "../reducers/app";
import print from "./print";
import business from "./business";

let noticeMessage = {
  messageQueue: [],
  yesBusinessTagList: [], // 已处理的通知的业务标记，最近的100个即可  value中是业务标识
  lastTime: new Date().getTime(), // 时间戳(毫秒)
  intervalVal: 10000,              // ajax间隔时间(毫秒) 实时通知时间修改为10秒 -- 当自己操作的时候立即响应，其他操作的10秒后显示。

  notificaTimmerTime: 1000, // 收到通知后处理的延时时间，单位(毫秒)
  ajaxBillTimer: '',
  ajaxBusyConsumeTimer: '',
  ajaxHandNumberTimer: '',
  ajaxEmployeeTimer: '',
  ajaxEmployeeAppointClockTimer: '',
  ajaxRoomTimer: '',
  ajaxAppointOrderTimer: '',
  ajaxCallServeTimer: '',
  ajaxBookingTimer: '',
  ajaxEmployeeRemindTimer: '',
  ajaxExpireTimer: '',
  ajaxGetCallServeConfirmListTimer: '',
  ajaxBookingOnlineTimer: '',
  ajaxBookingStoreTimer: '',
  ajaxOrderInfoTimer: '',
  ajaxRoomOpenTimer: '',
  ajaxEmployeeBookingTimer: '',
}

//接收连接的值类型
noticeMessage.noticeType = {
  hand_number: 1,
  room: 2,
  employee: 3,
  bill: 4,
  booking: 5,
  service_expire: 7,
  call_service: 8,
  appoint_order: 9,
  employee_remind: 10,
  booking_online: 11,
  call_service_update: 12, // 呼叫服务 状态变动
  front_order: 19, // 收银，订单通知
  wait_remind: 24, // 待提醒数据
  config_center: 26, // 配置中心
  consume_transfer: 28, // 消费转移
  room_open: 29, // 开房记录
  print_consume_delete: 30, // 删除消费
  print_bill_back: 31, // 退单
  print_consume_transfer: 33, // 消费转移
  employee_booking: 34, // 技师预约
  offline_data: 35, // 边缘数据
  consume_lock: 39, // 消费价格锁定
  room_remark: 40, // 房间备注
};

// 将消息的业务ids和类型放入
noticeMessage.manualPushQueueList = (noticeInfoList) => {
  /**
   * noticeInfoList:
   * [
   *  {
   *    'businessTag': 'xxx', 业务消息标识(就是所谓的父级标识，一个终端下是唯一的，和其他终端可能会有重复)
   *    'tag': 'xxx', 消费唯一标识
   *    'type': 'xxx', 消息的类型
   *  },
   *  {},
   *  ...
   * ]
   */
  for (let i = 0; i < noticeInfoList.length; i++) {
    noticeMessage.messageQueue.push(noticeInfoList[i])
  }
}

noticeMessage.handleMessage = function (type) {
  const dispatch = common.dispatch

  if ((parseInt(type) === noticeMessage.noticeType.bill) || (parseInt(type) === noticeMessage.noticeType.consume_lock)) {
    // 账单、价格锁定

    clearTimeout(noticeMessage.ajaxBillTimer);

    noticeMessage.ajaxBillTimer = setTimeout(function () {

      // 获取并处理服务中的账单
      biz.getAndHandleServeBill();

    }, noticeMessage.notificaTimmerTime);

    return;
  } else if (parseInt(type) === noticeMessage.noticeType.room_open) {
    // 开房中的记录

    clearTimeout(noticeMessage.ajaxRoomOpenTimer)

    noticeMessage.ajaxRoomOpenTimer = setTimeout(function () {

      common.axios('get', common.apiUrl('FINANCE/room-open'), {}).then((resultData) => {

        biz.handleForRoomOpen(resultData)

      }).catch((errorObj) => {
        // 失败
        common.toast(errorObj.error);
      }).finally(() => {

      })

    }, noticeMessage.notificaTimmerTime);

    return;
  } else if (parseInt(type) === noticeMessage.noticeType.employee) {
    //技师

    clearTimeout(noticeMessage.ajaxEmployeeTimer)

    noticeMessage.ajaxEmployeeTimer = setTimeout(function () {
      // 获取并处理技师动态数据
      biz.employeeTrendsForInitLoad();
    }, noticeMessage.notificaTimmerTime)

    return;
  } else if (parseInt(type) === noticeMessage.noticeType.room || parseInt(type) === noticeMessage.noticeType.room_remark) {
    //房间

    clearTimeout(noticeMessage.ajaxRoomTimer);

    noticeMessage.ajaxRoomTimer = setTimeout(function () {

      // 加载并处理房间动态数据
      biz.getAndHandleRoomTrends();

    }, noticeMessage.notificaTimmerTime)

    return;
  } else if (parseInt(type) === noticeMessage.noticeType.hand_number) {
    //手牌

    clearTimeout(noticeMessage.ajaxHandNumberTimer);

    noticeMessage.ajaxHandNumberTimer = setTimeout(function () {
      // 获取并处理手牌信息
      biz.getAndHandleHandNumber()
    }, noticeMessage.notificaTimmerTime);

    return;
  } else if (parseInt(type) === noticeMessage.noticeType.booking) {
    //预约客户

    clearTimeout(noticeMessage.ajaxBookingTimer)

    noticeMessage.ajaxBookingTimer = setTimeout(function () {
      // todo 在线预约未启用
      // app.mainCenter.$refs.booking.notifyStoreResponse();
    }, noticeMessage.notificaTimmerTime);

    return;
  } else if (parseInt(type) === noticeMessage.noticeType.employee_remind) {
    //技师提醒数据

    //服务未到钟的数据
    clearTimeout(noticeMessage.ajaxEmployeeRemindTimer)

    noticeMessage.ajaxEmployeeRemindTimer = setTimeout(function () {
      // 获取并处理未落钟的消费数据
      biz.getAndHandleNoEndConsume()
    }, noticeMessage.notificaTimmerTime)

    return;
  } else if (parseInt(type) === noticeMessage.noticeType.service_expire) {
    //服务到期

    //服务到期
    clearTimeout(noticeMessage.ajaxExpireTimer)

    noticeMessage.ajaxExpireTimer = setTimeout(function () {
      biz.getExpire().then(function () {

      }).catch(function (expireData) {
        common.toast(expireData);
      });
    }, noticeMessage.notificaTimmerTime)

    return;
  } else if (parseInt(type) === noticeMessage.noticeType.call_service) {
    //呼叫服务

    // 方法里有延时，故这里不需要
    biz.callService();

    return;
  } else if (parseInt(type) === noticeMessage.noticeType.call_service_update) {
    //呼叫服务被确认

    // 方法里有延时，故这里不需要
    biz.getCallServiceUpdateList();

    return;
  } else if (parseInt(type) === noticeMessage.noticeType.appoint_order) {
    //小程序

    // 方法里有延时，故这里不需要
    biz.appointOrderData();

    return;
  } else if (parseInt(type) === noticeMessage.noticeType.wait_remind) {
    // 待提醒数据

    biz.getWaitRemind().then(() => {

    }).catch((waitRemindData) => {
      common.toast(waitRemindData)
    });

    return;
  } else if (parseInt(type) === noticeMessage.noticeType.employee_booking) {
    // 技师预约
    clearTimeout(noticeMessage.ajaxEmployeeBookingTimer);

    noticeMessage.ajaxEmployeeBookingTimer = setTimeout(function () {

      var tempUrl = common.apiUrl('SCHEDULE/employee-booking/info')

      common.axios('get', tempUrl, {}).then((resultData) => {

        dispatch(setEmployeeBookingList(resultData))

      }).catch((errorObj) => {
        // 失败
        common.toast(errorObj.error);
      }).finally(() => {

      })
    }, noticeMessage.notificaTimmerTime)

    return;
  }

  try {
    // json字符串
    var tempContent = JSON.parse(type);

    if (tempContent && tempContent.status && (tempContent.status != undefined) && tempContent.data && (tempContent.data != undefined)) {
      if (tempContent.data == 'front-refresh') {
        // 刷新页面 todo
        // app.systemNotice.$refs['notice'].beginAction();
        return;
      }
    }

    if (tempContent && (typeof tempContent.type != "undefined") && tempContent.type && parseInt(tempContent.type) === noticeMessage.noticeType.booking_online) {
      // 在线预约

      clearTimeout(noticeMessage.ajaxBookingOnlineTimer)

      noticeMessage.ajaxBookingOnlineTimer = setTimeout(function () {
        // var bookingDate = tempContent.date;
        //
        // 没有星级和技师的占用功能了
        // //当日期和当前选中的日期相同的时候，则拉取技师和星级的占用数据
        // biz.getReserveAndLevelList(bookingDate);
        //
        // //当日起和当前选中的日期相同时，拉取数据
        // if (app.mainCenter.$refs.booking.date == bookingDate) {
        //   //重新拉取预约信息
        //   app.mainCenter.$refs.booking.getOnlineData();
        // }
      }, noticeMessage.notificaTimmerTime)

      return;
    } else if (tempContent && (typeof tempContent.type != "undefined") && tempContent.type && parseInt(tempContent.type) === noticeMessage.noticeType.booking) {
      // 在店预约

      clearTimeout(noticeMessage.ajaxBookingStoreTimer)

      noticeMessage.ajaxBookingStoreTimer = setTimeout(function () {
        // var bookingDate = tempContent.date;
        //
        // 没有星级和技师的占用功能了

        // //当日期和当前选中的日期相同的时候，则拉取技师和星级的占用数据
        // getReserveAndLevelList(bookingDate);
        //
        // //当日起和当前选中的日期相同时，拉取数据
        // if (app.mainCenter.$refs.booking.date == bookingDate) {
        //   //重新拉取预约信息
        //   app.mainCenter.$refs.booking.notifyStoreResponse();
        // }

      }, noticeMessage.notificaTimmerTime)

      return;
    } else if (tempContent && (typeof tempContent.type != "undefined") && tempContent.type && parseInt(tempContent.type) === noticeMessage.noticeType.config_center) {
      // 配置中心

      if (typeof tempContent.data == "undefined") {
        console.log('config_center notice error[no data]');
        return;
      }

      let tempContentData = tempContent.data;
      let configName = (typeof tempContentData.config != "undefined") ? tempContentData.config : '';
      let configValue = (typeof tempContentData.value != "undefined") ? tempContentData.value : '';
      let userIds = (typeof tempContentData.userIds != "undefined") ? tempContentData.userIds : [];

      if (!configName) {
        console.log('config_center notice error[no name]');
        return;
      }

      // 没有门店的话，则表示所有的都清空
      if ((!userIds) || (userIds.length <= 0)) {
        // 处理对应配置要处理的内容
        noticeMessage.handleFroConfigCenter(configName, "", common.getCookie(configName));

        common.deleteCookie(configName)
        return;
      }

      // 没有配置的值，则表示所有的都清空
      if (configValue.toString().length <= 0) {
        // 处理对应配置要处理的内容
        noticeMessage.handleFroConfigCenter(configName, "", common.getCookie(configName));

        common.deleteCookie(configName)
        return;
      }

      // 既有门店、也有配置的值，则表示要对应的设置cookie的值
      // 判断当前门店是否在对应要设置的门店中
      // 在：则对应设置cookie
      // 不在：将对应的cookie删除
      let nowUserId = common.getCookie('user.id');
      nowUserId = nowUserId || 0;
      nowUserId = parseInt(nowUserId)

      for (let i = 0; i < userIds.length; i++) {
        userIds[i] = parseInt(userIds[i])
      }

      if (userIds.indexOf(nowUserId) < 0) {
        // 不存在要通知的门店中，删除cookie

        // 处理对应配置要处理的内容
        noticeMessage.handleFroConfigCenter(configName, "", common.getCookie(configName));

        common.deleteCookie(configName);

        return;
      }

      // 在对应要处理的门店ids中，设置对应的cookie值  10年

      // 处理对应配置要处理的内容
      noticeMessage.handleFroConfigCenter(configName, configValue, common.getCookie(configName));

      common.setCookie(configName, configValue, 3650)

      return;
    } else if (tempContent && (typeof tempContent.type != "undefined") && tempContent.type && parseInt(tempContent.type) === noticeMessage.noticeType.consume_transfer) {
      // 消费转移

      if (typeof tempContent.ids != "undefined") {
        if (tempContent.ids) {
          print.printForTransfer(JSON.stringify(tempContent.ids)); // 鞋吧的消费转移小票
        }
      }

      return;
    } else if (tempContent && (typeof tempContent.type != "undefined") && tempContent.type && parseInt(tempContent.type) === noticeMessage.noticeType.print_consume_transfer) {
      // 消费转移 打印

      if (typeof tempContent.ids != "undefined") {
        if (tempContent.ids) {
          print.printForConsumeTransfer(JSON.stringify(tempContent.ids)); // PC收银的消费转移小票
        }
      }

      return;
    } else if (tempContent && (typeof tempContent.type != "undefined") && tempContent.type && parseInt(tempContent.type) === noticeMessage.noticeType.print_consume_delete) {
      // 消费删除 打印

      if (typeof tempContent.consumeIds != "undefined") {
        if (tempContent.consumeIds) {
          print.printForConsumeDelete(JSON.stringify(tempContent.consumeIds));
        }
      }

      return;
    } else if (tempContent && (typeof tempContent.type != "undefined") && tempContent.type && parseInt(tempContent.type) === noticeMessage.noticeType.print_bill_back) {
      // 退单 打印

      if (typeof tempContent.consumeIds != "undefined") {
        if (tempContent.consumeIds) {
          print.printForBillBack(JSON.stringify(tempContent.consumeIds));
        }
      }

      return;
    } else if (tempContent && (typeof tempContent.type != "undefined") && tempContent.type && parseInt(tempContent.type) === noticeMessage.noticeType.offline_data) {
      // 离线数据处理

      if (typeof tempContent.offline_data != "undefined") {
        if (tempContent.offline_data) {
          biz.saveOffline(tempContent.offline_data)
        }
      }

      return;
    }

    // 打印
    if ((typeof tempContent.action !== "undefined") && (tempContent.action !== undefined)) {
      switch (tempContent.action) {
        case "print3":

          print.print(tempContent.content, [
            'printerName=print3',
            'printWidth=' + print.printWidth,
          ], 1);

          // $.ajax({
          //   type: "POST",
          //   url: print.print + "?printerName=print3&printWidth=" + print.printWidth + "&json=" + tempContent.content,
          //   data: {},
          //   dataType: "json",
          //   success: function (result) {
          //     console.log(result);//success
          //   },
          //   error: function () {
          //     // alert('出错了');
          //   }
          // });

          break;
      }
    }

  } catch (error) {
    console.log(error)
  }

}

// 立即触发处理实时通知的数据
noticeMessage.triggerHandleMessageTimmer = ''
noticeMessage.triggerHandleMessage = function () {

  try {
    // let tempDelayTime = 500; // 默认延时500毫秒，给传输的时间
    let tempDelayTime = 800; // 改为800秒延时(主要是：mqtt的通知那边每个消息是2毫秒延时，消费者断开之后会有一个50毫秒延时，这里就已露出300ms用来给出响应)

    if (common.isOfflineMode()) {
      // 店端，多给1秒，因为店端的机制不同，最多会延时1秒
      tempDelayTime = tempDelayTime + 1000;
    }

    clearTimeout(noticeMessage.triggerHandleMessageTimmer)
    noticeMessage.triggerHandleMessageTimmer = setTimeout(() => {
      noticeMessage.lastTime = 0
    }, tempDelayTime); // 延时1.5秒后立即触发，给店端的1秒反应时间，以及传输时间
  } catch (e) {
    console.log("手动立即触发实时通知处理失败:" + e.toString())
  }

}

// 频繁的通知会有相同的type，等待几秒后，去掉重复的请求，减少无用的ajax
setInterval(function () {

  var size = noticeMessage.messageQueue.length
  if (size === 0) {
    return
  }

  if (new Date().getTime() - noticeMessage.lastTime < noticeMessage.intervalVal) {
    return
  }

  noticeMessage.lastTime = new Date().getTime()

  // 去重
  var mySet = new Set()
  let myTypeObj = {}
  for (var i = 0; i < size; i++) {
    // shift: 先进先出
    let tempOne = noticeMessage.messageQueue.shift()

    // 已经处理过的消息id，则跳过
    // 兼容边缘和老的通知里，没有消息标记的
    try {
      if((typeof tempOne['businessTag'] !== "undefined") && tempOne['businessTag'] && (tempOne['businessTag'].length > 0)) {
        if(noticeMessage.yesBusinessTagList.indexOf(tempOne['businessTag']) !== -1) {
          continue;
        }
      }
    } catch (e) {
      console.log("消息过滤失败:" + e.toString())
    }

    // 已存在，跳过，去重
    if(typeof myTypeObj[tempOne['type']] !== "undefined") {
      continue;
    }

    mySet.add(tempOne)
    myTypeObj[tempOne['type']] = tempOne['type']
  }

  if (mySet.size < size) {
    console.log(common.dateFormat(new Date(), 'hh:ii:ss'), "handleMessage:", size, "=>", mySet.size)
  } else {
    console.log(common.dateFormat(new Date(), 'hh:ii:ss'), "handleMessage:", size)
  }
  for (let item of mySet) {
    noticeMessage.handleMessage(item['type'])

    noticeMessage.yesBusinessTagList.push(item['businessTag'])
  }

  // 已通知的内容，只保留最近的100个
  noticeMessage.yesBusinessTagList = noticeMessage.yesBusinessTagList.slice(-100);

}, 500) // 因为从库的同步可能会有延时，所以这里需要延时

// 根据配置中心的值做后续处理
noticeMessage.handleFroConfigCenter = (configName, configValue, lastConfigValue) => {
  try {
    if (!configName) {
      return;
    }

    configName = configName.toString();

    switch (configName) {
      case 'pc-front-pre':
        // 预发布
        preChangeForInitWebSocket(configValue, lastConfigValue);
        break;

      default:
        break;
    }
  } catch (e) {

  }
}

// 通知预发布版本切换
function preChangeForInitWebSocket(preValue, lastPreValue) {
  // 判断要切换的版本，和当前所在版本是否相同，是的话则不动，否则切换

  // 本地测试版不动
  if (window.location.host == 'localhost') {
    return;
  }

  var changeVersion = ''; // 要切换的版本
  if (preValue) {
    // 预发布版本
    changeVersion = '/pre/index.html';
  } else {
    // 正式版本
    changeVersion = '/v2/index.html';
  }
  changeVersion = changeVersion.toString();

  // 检测当前的版本
  var nowVersion = window.location.pathname
  nowVersion = nowVersion.toString();

  /**
   * 1、如果版本不同直接提示跳转
   * 2、如果版本相同
   *  如果是正式版，不动
   *  如果是预发布版本，检测预发布的值和之前的值是否相同
   *    相同：不动
   *    不同：跳转
   */
  if (changeVersion !== nowVersion) {
    // 版本不同在，直接跳转
    redirectForPreChangeForInitWebSocket(changeVersion);

    return;
  }

  // 版本相同

  // 判断如果是正式版，则不动
  if (changeVersion === '/v2/index.html') {
    return;
  }

  // 预发布的版本，判断预发布的值同之前的是否一样，一样的不动；不一样的则更新
  lastPreValue = lastPreValue ? lastPreValue.toString() : ''
  preValue = preValue ? preValue.toString() : '';

  if (preValue === lastPreValue) {
    // 相同：不动
    return;
  }

  // 不同：更新
  redirectForPreChangeForInitWebSocket(changeVersion);
}

function redirectForPreChangeForInitWebSocket(changeVersion) {
  var version = Math.random();

  var url = ''

  if (typeof window.location == "undefined") {
    url = 'https://www.ku52.cn';
  } else {
    let protocol = 'https:'

    if (typeof window.location.protocol != "undefined") {
      var tempProtocol = window.location.protocol;
      tempProtocol = tempProtocol.toString();

      if ((tempProtocol == 'http:') || (tempProtocol == 'https:')) {
        protocol = tempProtocol;
      }
    }

    let host = 'www.ku52.cn';

    if (typeof window.location.host != "undefined") {
      host = window.location.host;
    }

    url = protocol + '//' + host;
  }

  url = url.toString()

  if (url[url.length - 1] == '/') {
    url = url.substr(0, url.length - 1);
  }

  url = url + changeVersion + '?v=' + version

  // 刷新通知
  // app.systemNotice.$refs['notice'].refreshUrl = url;
  // app.systemNotice.$refs['notice'].beginAction();
}

export default noticeMessage;