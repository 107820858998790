import common from "./common";
import noticeMessage from "./noticeMessage";
import business from "./business";

let mqttClient = {
  client: null
}

// mqtt的处理
mqttClient.startMqtt = () => {

  if(typeof mqttClient.client === "undefined") {
    mqttClient.client = null
  }

  if(mqttClient.client != null) {
    mqttClient.client.end();
  }


  // mqtt跟着ip一起切换。ip切换的时候，mqtt才进行切换。自己不进行切换
  // 原因1：一般mqtt都是和ip配套使用的
  // 原因2：mqtt自己会断开重连，不能依赖mqtt的是否可用。跟着ip走，一般ip可用，对应的mqtt就可用

  // mqtt的连接的地址
  var connectUrl = common.mqttUrl();

  let userId = business.userInfo?business.userInfo['user_id']:0

  // 没有门店的，则跳过
  if(!userId) {
    common.redirectToLogin()
    return;
  }

  // 设备标识：localstorag里
  // let tempStorage = localStorage; // 因为酷鼠助手每次双击都会打开新的浏览器，localstorage相同，目前这里的clean为true，丢失的通知不管，且设备标识就用随机的在SessionStorage
  let tempStorage = sessionStorage;
  var deviceTag = tempStorage.getItem("pc_device_tag")
  if((deviceTag == undefined) || (!deviceTag)) {
    // 门店ID开头 + ：+ 当前时间戳的微秒级别 + 随机的5位字符串 -- 当门店ID为4位的时候，这里是23位字符串(4 + 1 + 13 + 4)
    deviceTag = userId + ':' + (new Date().getTime()) + (common.getRoundNum(10000, 99999));

    tempStorage.setItem('pc_device_tag', deviceTag);
  }

  var username = deviceTag; // 字符串类型 用设备标识作为用户名，密码为是经过密码规则算出来的
  var password = common.handleMqttPwd(username)

  var options = {
    keepalive: 30,
    clientId: deviceTag, // (这里生成的规则本来就是门店ID开头的) 用门店ID+':'+设备标识作为clientId。这样可以做到，换了门店，就不会再对之前的门店进行处理数据了
    // protocolId: 'MQTT',
    // protocolVersion: 4,
    // clean: false,
    clean: true, // 20211010: 因为酷鼠助手每次双击都会打开新的浏览器，localstorage相同，目前这里的clean为true，丢失的通知不管，且设备标识就用随机的在SessionStorage
    reconnectPeriod: 1000,
    connectTimeout: 30 * 1000,
    username: username, // 字符串类型 用设备标识作为用户名
    password: password,
    will: {
      topic: '/will',
      payload: 'Connection Closed abnormally.',
      qos: 0,
      retain: false
    },
    rejectUnauthorized: false
  }

  mqttClient.client = window.mqtt.connect(connectUrl, options)

  mqttClient.client.on('connect', (() => {
    console.log("mqtt: 连接成功");

    mqttClient.client.subscribe("/finance/msg/" + userId, {qos: 1});
  }))

  mqttClient.client.on('message', function (topic, message) {
    var msg = JSON.parse(message.toString())

    // 正式版上的通知的格式，已修改，不使用
    if(typeof msg.payload == "undefined") {
      return;
    }

    var data = msg.payload; // 原来v1版本里，websocket通知的内容

    //判断需要拉取的值
    var type = data.content;

    console.log(common.dateFormat(new Date(), 'hh:ii:ss') + ' mqtt:' + type);

    noticeMessage.messageQueue.push({
      'type': type,
      'tag': '',
      'businessTag': "",
    });
  })

  /**
   * 1、前端连好，主动断开，触发的是：
   *  先是close，再是end
   *
   * 2、在连接的时候，连不上地址，触发的是：
   *  假的不可访问的地址，触发的是end事件
   *  真的可访问的，但是不能操作的，触发的: --- 给的是正确的地址，只是将端口号从9884改为9883
   *          先是offline，然后是close
   *          然后自动重试的过程中，一直是close
   *
   * 3、在连接成功后，因为服务端连不上了，触发的是？
   *  先是offline，然后是close
   *  接着一直是close --- 应该是mqtt的自动重连的
   *
   *  这个过程里，如果一旦mqtt又可以访问了，会自动连上的
   *
   * 4、在正常使用过程中，会重连，是因为什么？ --- 原因不清楚
   *  会自己触发offline，然后是close，然后又重连上了
   *
   */

  // end事件 -- 主动断开也会触发这个；连接的时候地址是错的连不上，也会触发
  mqttClient.client.on('end', function () {
    console.log("mqtt end")

  })

  // 在连接时失败触发的
  mqttClient.client.on("error", function (e) {
    console.log("mqtt error")

  })

  mqttClient.client.on("close", function (e) {
    console.log("mqtt close")

  })

  // offline，不要，因为offline之后，跟着close

}

export default mqttClient;